
@use "../../styles/partials/_variables" as *;
@use "../../styles/partials/_mixins" as *;

.checkout{
    display:flex;
    flex-direction: column;
    align-items: center;
    @include tablet{
        flex-direction:row;
        justify-content: space-between;   
        align-items: flex-start;
    }

    @include block_padding;

    ul{
        padding:0;
    }
    
    &__contact{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding:1rem;
        width: 60%;
        label{
            cursor: pointer;
            color:$AnnieSilver-Blue;
            text-decoration: underline;
        }
    }
    &__login{
        display: flex;
        justify-content: space-between;
        padding:1rem 0;
        &--input{
            display: flex;
            flex-direction: column;
            width: 45%;
            input{
                background-color: $AnnieSilver-Alabaster;
                color:$AnnieSilver-Black;
                height:$input-height;             
                border: 1px solid $AnnieSilver-Mercury;
                border-radius: $AnnieSilver-Radius;
                width:100%;
                padding-left:0.2rem;                 
                ::focus{
                    border-color: $AnnieSilver-Purple;
                    outline: none;
                }       
            }
        }
    }
    &__paypal{
        display:flex;
        flex-direction: column;
        align-items:center;
        width: 100%;        
        &--container{
            width:60%;
        }
    }
    &__list{
        display: flex;
        flex-direction: column;

    }
    &__info{
        padding-left:2rem;  
        display: flex;
        flex-direction: column;    
        width: 60%;
        @include tablet{
            width: 35%;
        }
        padding:1rem;
        background-color: $AnnieSilver-Alabaster;   
        border: 1px solid $AnnieSilver-Mercury;
        &--header{
            display: flex;
            justify-content: space-between;
        }  
    }
    &__item{
        display: flex;
        justify-content: space-between;     
        p{  
            margin: 1rem 0;
        }   
    }
    &__summary{
        ul{
            display: flex;
            justify-content: space-between;
        }
    }
}
