@use "../../styles/partials/_variables" as *;
@use "../../styles/partials/_mixins" as *;

  .cart{
    position: fixed;
    top: 0;
    right: -20rem; /* Start off screen */
    width: 20rem;
    height: 100%;
    background-color:white;
    transition: right 0.5s ease-in-out;
    z-index: 9999;
    display: flex;   
    flex-direction: column;    
    &__header{
      display: flex;
      padding:1rem;
      height:4.5rem;
      color:white;
      background-color: $AnnieSilver-Grey;
      h2{
        display: flex;
        align-items: center;
        padding-left: 3rem;
      }
      cursor: pointer;
    }

    &--open {
    right: 0;
    transition: right 0.5s ease-in-out;
    } 
    &__content{
      max-height: calc(100vh - 10rem); /* Adjust 100px as needed for other elements at the bottom of the viewport */
      overflow-y: auto; /* Enable vertical scrolling if content exceeds max-height */
      &::-webkit-scrollbar {
        width: 5px; /* Set the width of the scrollbar */
        &-thumb {
          background-color: $AnnieSilver-Grey; /* Set the color of the scrollbar thumb */
          border-radius: 3px; /* Round the corners of the thumb */
        }
      }
    }
    &__button{ 
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;
      button{      
        background: $AnnieSilver-Grey;
        width:60%;
        height:$button-height;
        border-radius:$button-radius;
        border: 1px solid transparent;
        color: white;
        cursor: pointer;
        h3{
          margin:0;
        }     
      }
      button:hover{
        background: $AnnieSilver-LightGrey;
      }
    }

    &__qty{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid $AnnieSilver-Grey;
      padding:1rem;
      &--update{
        display: flex;
        flex-direction: row;
        width:6rem;
        font-size: medium;
        justify-content: space-around;
        border:1px solid $AnnieSilver-Black;     
        p{
            margin: 0;
            padding:0 5px;
            cursor: pointer;
            align-self: center;
        }
      }

      &--remove{
        display:flex;
        justify-content: flex-start;
        img,p{
          margin:0;
          pointer-events: none;
          align-self: center;
        }
      }        
    }
    &__subtotal{
      display: flex;
      justify-content: space-between;      
    }
    h3{
      margin:1rem;
    }
  }
  
  

