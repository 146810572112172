@use "../../styles/partials/_variables" as *;
@use "../../styles/partials/_mixins" as *;
.product{
    display: flex;
    flex-direction: column;
    @include block_padding;
    @include tablet{
        flex-direction: row;
    }
    &__info{
        display: flex;
        flex-direction: column;
        padding:1rem;
        h2,label,li,h3{
            margin:1rem;
        }
      
        &--button{ 
            display: flex;
            justify-content: center;
            margin: 1rem;
            button{      
              background: $AnnieSilver-Purple;
              width:60%;
              height:$button-height;
              border-radius:$button-radius;
              border: 1px solid transparent;
              color: white;
              cursor: pointer;
              h3{
                margin:0;
              }
            }
            button:hover{
                background-color:$AnnieSilver-DarkPurple;
            }
            
          }
    }
    &__images{
        display:flex;
        flex-direction: column;
        padding:1rem;
        @include tablet{
            padding:2rem;
        }
        &--main{
            display: flex;
            border:1px solid $AnnieSilver-Mercury;
            width: 100%;
            @include tablet{
                width: 30rem;
            }
            @include desktop{
                width: 35rem;
            }
            
           
           img{
            width:100%;
           }
        }
        &--thumb{
            display: flex;
            padding:0.5rem 0;
            column-gap: 0.5rem;
            
            img{     
                border:1px solid $AnnieSilver-Mercury;
                width:5rem;
            }
        }
    }
    
}