@use "./variables" as *;

@mixin tablet{
  @media screen and (min-width: $tablet-breakpoint) {       
    @content;
  }
}
@mixin desktop{
  @media screen and (min-width : $desktop-breakpoint) {
    @content;    
  }
}

@mixin block_padding{
  padding: $m-padding-content-between;
  @include tablet{  
    padding: $t-padding-content-between;
  }
  @include desktop{
    padding: $d-padding-content-between;
  }
}