@use "../../styles/partials/_variables" as *;
@use "../../styles/partials/_mixins" as *;

.productcard{
    &__block{
        display: flex;
        flex-direction: column;   
        width:80%;
        padding:1rem;
        @include tablet{
            width:15rem;
        }         
        color:$AnnieSilver-Black;        
        img{
            width:100%;
            cursor: pointer;
        }   
    }
    &__line{
        display: flex; 
        justify-content: space-between;
        padding:1rem;
        color:$AnnieSilver-Black;   

        img{
            padding:1rem;
            width:4rem;
            height:4rem;
            cursor: pointer;
        }   

    }
    &__info{
        display:flex;
        flex-direction: column;    
        justify-content: space-between;
        cursor: pointer;

        @include tablet{
            height:6rem;
        }
        padding:1rem;     
        p{
            margin:0; 
        }  
        
    }
    &__price{
        padding:0;
        font-size: 1rem;
        color:$AnnieSilver-Black;
    }   

}
