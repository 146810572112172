@use "../../styles/partials/_variables" as *;
@use "../../styles/partials/_mixins" as *;

@use "../../styles/global.scss";

.header{
    display:flex;
    flex-direction: column;
    position:relative;
    @include block_padding;
    color:$AnnieSilver-Black;
    &__logo{
        img{
            width:240px;
        }
        @include tablet{
            img{
                width:320px;
            }
        }
        
    }
    &__container{
        display: flex;
        flex-direction: column;
        align-items: center;
        @include block_padding;
        @include tablet{
            flex-direction: row;
            justify-content: space-between;
        }
    }
    &__group{
        display:flex;
        justify-content: flex-end;
        width: 100%;
        column-gap: 2rem;
    }


    &__navbar{
        display: flex;
        justify-content: center;
        align-items: center;
        @include tablet{
            justify-content: space-between;
        }     
        color:$AnnieSilver-Black;
      
        ul{
            display:flex;
            margin:0;
            padding:0;
  
        }
        li{
            padding: 0 2rem;
            list-style: none;
            font-size: 1.1rem;           
            font-weight: 500;            
            @include tablet{
                font-size: 1.3rem;           
                font-weight: 500;      
            }   
        }
        li:hover{
            color:$AnnieSilver-Grey;
            cursor:pointer;
        }

    }
    &__nav{
        display: flex;   
        justify-content: space-between;   
    }
    &__menu{
        border-right:1px solid $AnnieSilver-Grey;
        padding:0;        
        position:relative;
        &--highlight{
            color: $AnnieSilver-Grey;
        }
        
    }
    &__floating{
        position: absolute;
        top: 93%; 
        left: 50%;
        @include tablet{
            top: 89%; 
            left: 20%;
        }
        @include desktop{
            left: 14%;
        }
        background-color:$AnnieSilver-Alabaster;
        border: 1px solid $AnnieSilver-Mercury;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        padding: 10px;
        z-index: 100; 
        ul{
            display: flex;
            flex-direction: column;
            width:auto;
            list-style: none;
            padding: 0;
            margin: 0;
            justify-content: center;
        }
        li{
            padding: 5px;
            width: max-content;
            font-size:0.9rem;
            font-weight: 400;
            cursor: pointer;         
            border-bottom: 1px solid $AnnieSilver-Purple;           
        }
        li:hover{
            border-bottom: 1px solid $AnnieSilver-Grey;
        }
    }
    &__search{                
        display: none;   
        @include tablet{
            display: flex;
            align-items: center;
            position: relative;
            width: 40%;
        }
  
        @include tablet{
            input{
                display: flex;
                background-color: $AnnieSilver-Alabaster;
                color:$AnnieSilver-Black;
                height:$input-height;             
                border: 1px solid $AnnieSilver-Mercury;
                border-radius: $AnnieSilver-Radius;
                width:100%;
                padding-left:2.5rem;            
            }             
        }
        input:focus{
            border-color: $AnnieSilver-Purple;
            outline: none;
        }       
        img{
            position: absolute;
            top: 30%;         
            left:3%;                                 
        }        
    }

    &__account{        
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        p{
            display: none;           
            @include tablet{
                margin:0 1rem;
                display: flex;
            }
        }
        &--drop{
            position: absolute;
            background-color:$AnnieSilver-Alabaster;
            border: 1px solid $AnnieSilver-Mercury;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            padding: 10px;
            top: 48%;         
            left: 80%;  
            z-index: 100;
            p{
                cursor: pointer;         
                border-bottom: 1px solid $AnnieSilver-Purple;
            }
            p:hover{
                border-bottom: 1px solid $AnnieSilver-Grey;
            }
        }
    }
    &__cart{
        display: flex;
        position: relative;
        cursor: pointer; 
        p{
            position: absolute;
            top: 7%;         
            left: 53%;      
            font-weight: 400;
        }
    }
    a{
        color:inherit;
        text-decoration: none;
    }
}
