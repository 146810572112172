$tablet-breakpoint: 768px;
$desktop-breakpoint:1280px;


$button-height:2.5rem;
$button-radius:1.25rem;
$input-height:2.5rem;
$AnnieSilver-Radius:4px;


$AnnieSilver-Black:#323232;
$AnnieSilver-Purple:#BBA2CA;
$AnnieSilver-Blue:#009CDE;
$AnnieSilver-LightGrey:#758CA3;
$AnnieSilver-Grey:#859cb3;
$AnnieSilver-Silver:#AFAFAF;
$AnnieSilver-White: #FFFFFF;
$AnnieSilver-Mercury:#E1E1E1;
$AnnieSilver-Alabaster:#FAFAFA;
$AnnieSilver-AlertColor: #D22D2D;
$AnnieSilver-DarkPurple:#9c89b8;

$m-padding-content-between:1rem;
$t-padding-content-between:2rem;
$d-padding-content-between:2rem;