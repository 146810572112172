@use "./partials/mixins" as *;
@use "./partials/variables" as *;
body {
    margin: 0 auto;
    max-width: 1280px;
    align-self: center;
    font-family: Arial, sans-serifapple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  
  
  }
  
//   code {
//     font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//       monospace;
//   }
a{
    color:inherit;
    text-decoration: none;
}

label{
    padding:0.5rem 0 ;
    color:$AnnieSilver-Black;
}

h1 {
    margin:1rem 0;
    font-size: 1.625rem;
    font-weight: 600;
    @include tablet{
        font-size: 2rem;
        font-weight: 600;      
    }
}
h2 {
    margin:0;
    font-size: 1.3rem;
    font-weight: 600;
    @include tablet{
        font-size: 1.6rem;
        font-weight: 600;      
    }
}


h3 {
    font-size: 1.1rem;
    font-weight: 600;
    @include tablet{
        font-size: 1.2rem;
        font-weight: 600;     
    }
}  

h4 {
    font-size: 1rem;
    font-weight: 600;
    @include tablet{
        font-size: 1rem;
        font-weight: 600;     
    }
}
h5 {
    font-size: 0.7rem;
    font-weight: 500;
    @include tablet{
        font-size: 0.7rem;
        font-weight: 600;     
    }
}
p{
    font-size: 0.8125rem;
    font-weight: 400;
    @include tablet{
        font-size: 0.875rem;
        font-weight: 400;   
    }    
}


hr{
    color:$AnnieSilver-Grey;
    height:1px;
}