@use "../../styles/partials/_variables" as *;
@use "../../styles/partials/_mixins" as *;

.collection{
    &__banner{
        padding:1rem;
        @include tablet{
            padding: 0 2rem;
        }
        img{
            width: 100%;
        }
    }
    &__products{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @include tablet{
            justify-content: space-between;
        }
        align-self: flex-start;
        @include block_padding;
        column-gap: 2rem;
    }
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}