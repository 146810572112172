@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins.scss" as *;

.modal {
  position: fixed;
  z-index: 1000;
  top:0%;
  left: 0%;
  width: 100vw;
  height: 100vh;
  display: flex;
  background-color: rgba(19, 24, 44, 0.7);

  &__container {
    background-color: rgba(255, 255, 255, 100);
    position: absolute;
    top: 15vw;
    left:15vw;
    width: 60vw;
 
    @include tablet{
      // top: 15%;
      // left:30%;
      // width: 35%;
      // height: 60%;   
      top: 15vw;
      left:38vw;
      width: 38vw;
    }  
    
    z-index: 1050;
    padding: 2rem;
    border: 1px solid $AnnieSilver-Mercury;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8); /* Box shadow */
    display: flex;
    flex-direction: column;
    align-items: center;
    h1{
      padding:1rem;
    }
    input{
      margin:1rem 0;
      background-color: $AnnieSilver-Alabaster;
      color:$AnnieSilver-Black;
      height:$input-height;             
      border: 1px solid $AnnieSilver-Mercury;
      border-radius: $AnnieSilver-Radius;
      width:100%;
      padding-left:0.2rem;                 
    }    
    input:focus{
      border-color: $AnnieSilver-Grey;
      outline: none;
   }     
  }

  &__opt{
    display: flex;
    button{      
      background: $AnnieSilver-Grey;
      margin:1rem;
      width:60%;
      height:2.5rem;
      border-radius:1.25rem;
      border: 1px solid transparent;
      color: white;
      cursor: pointer;
    }    
    button:hover{
      background: $AnnieSilver-LightGrey;
    }
  }
  h4{
    cursor: pointer;
    margin: 0;
  }
  h5{
    cursor: pointer;
    margin: 0;        
  }
  h5:hover{
    color: $AnnieSilver-LightGrey;
  }
}