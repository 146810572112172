@use "../../styles/partials/_variables" as *;
@use "../../styles/partials/_mixins" as *;
.shoppingcart{
    display: flex;   
    flex-direction: column;
    padding:2rem;
    @include tablet{
      flex-direction:row;
    }

    &__button{ 
      display: flex;
      justify-content: center;

      button{
        background-color: $AnnieSilver-Purple;  
        width: 92%;
        height:$button-height;
        border-radius:$button-radius;
        border: 1px solid transparent;
        cursor: pointer;
        h3{
          margin:0;
          color:white;
        }
      }
      button:hover{
        background: $AnnieSilver-DarkPurple;
      }
    }

    &__qty{
      display: flex;
      flex-direction: row;      
      justify-content: flex-end;
      align-items: baseline;
      cursor: pointer;
      @include tablet{
        padding:1rem;
        
      }  
  

      &--update{
        display: flex;
        width:6rem;
        justify-content: space-around;
        border:1px solid $AnnieSilver-Black;   
        margin:1rem;
        p{
          margin: 0;
          padding:0 5px;
          cursor: pointer;
          align-self: center;
        }
      }
      &--remove{
        display:flex;

        margin:0 1rem;
        img{
          align-self: center;
          margin:0;
          pointer-events: none;
        }
      }           
    }
    &__content{
        display: flex;
        flex-direction: column;
        @include tablet{
          border-right: 1px solid $AnnieSilver-Mercury;
          padding-right: 3rem;
        }
        
        h2{
          display: flex;
          justify-content: center;
          margin-bottom: 2rem;
        }
    }
    &__item {
        display: flex;
        flex-direction:column;
        @include tablet{
          flex-direction:row;
          justify-content: space-between;
        }
    }
    &__summary{     
      padding: 1rem;
      @include tablet{
        padding-left: 2rem;
        width: 35%;
      }
        
        h2{
          padding:0;
        }
        ul{
          display: flex;
          justify-content: space-between;
          padding:0;
        }
        li{
          list-style: none;
          
        }
    }   
  }
  